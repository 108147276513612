import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Dropdown, List, Menu, Tag, Tooltip, Typography } from 'antd';
// import { Link } from 'react-router-dom';
import { DeleteOutlined, MoreOutlined, UnlockOutlined } from '@ant-design/icons';
import { CorpUser, CorpUserStatus, EntityType, DataHubRole } from '../../../types.generated';
import CustomAvatar from '../../shared/avatar/CustomAvatar';
import { useEntityRegistry } from '../../useEntityRegistry';
import ViewResetTokenModal from './ViewResetTokenModal';
import useDeleteEntity from '../../entity/shared/EntityDropdown/useDeleteEntity';
import SelectRole from './SelectRole';

type Props = {
    user: CorpUser;
    canManageUserCredentials: boolean;
    selectRoleOptions: Array<DataHubRole>;
    onDelete?: () => void;
    refetch?: () => void;
};

const UserItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
        width: auto !important;
    }
`;

const UserHeaderContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

const MenuIcon = styled(MoreOutlined)<{ fontSize?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.fontSize || '24'}px;
    height: 32px;
    margin-left: 5px;
`;

export default function UserListItem({ user, canManageUserCredentials, selectRoleOptions, onDelete, refetch }: Props) {
    const entityRegistry = useEntityRegistry();
    const [isViewingResetToken, setIsViewingResetToken] = useState(false);
    const displayName = entityRegistry.getDisplayName(EntityType.CorpUser, user);
    const isNativeUser: boolean = user.isNativeUser as boolean;
    const shouldShowPasswordReset: boolean = canManageUserCredentials && isNativeUser;
    const castedCorpUser = user as any;
    const userRelationships = castedCorpUser?.roles?.relationships;
    const userRole = userRelationships && userRelationships.length > 0 && (userRelationships[0]?.entity as DataHubRole);
    const userRoleUrn = userRole && userRole.urn;

    const { onDeleteEntity } = useDeleteEntity(user.urn, EntityType.CorpUser, user, onDelete, false, true);

    const getUserStatusToolTip = (userStatus: CorpUserStatus) => {
        switch (userStatus) {
            case CorpUserStatus.Active:
                return 'The user has logged in.';
            default:
                return '';
        }
    };

    // const getUserStatusColor = (userStatus: CorpUserStatus) => {
    //     switch (userStatus) {
    //         case CorpUserStatus.Active:
    //             return REDESIGN_COLORS.GREEN;
    //         default:
    //             return ANTD_GRAY[6];
    //     }
    // };

    const userStatus = user.status; // Support case where the user status is undefined.
    const userStatusToolTip = userStatus && getUserStatusToolTip(userStatus);
    const isActive = userStatus === CorpUserStatus.Active;

    return (
        <List.Item>
            <UserItemContainer>
                {/* TODO: Disabling the link for now */}
                {/* <Link to={entityRegistry.getEntityUrl(EntityType.CorpUser, user.urn)}> */}
                <UserHeaderContainer>
                    <CustomAvatar
                        size={32}
                        name={displayName}
                        photoUrl={user.editableProperties?.pictureLink || undefined}
                    />
                    <div className="pl-2 pr-3">
                        <Typography.Title className="f-text-big-content f-color-dark-black-s80 mb-0">
                            {displayName}
                        </Typography.Title>
                        <Typography.Title className="f-text-content f-color-dark-black-s50 my-0">
                            {user.username}
                        </Typography.Title>
                    </div>
                    {userStatus && (
                        <Tooltip overlay={userStatusToolTip}>
                            <Tag className={isActive ? 'green-tag' : ''}>{userStatus}</Tag>
                        </Tooltip>
                    )}
                </UserHeaderContainer>
                {/* </Link> */}
            </UserItemContainer>
            <ButtonGroup>
                <SelectRole
                    user={user}
                    userRoleUrn={userRoleUrn || ''}
                    selectRoleOptions={selectRoleOptions}
                    refetch={refetch}
                />
                <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu>
                            <Menu.Item
                                disabled={!shouldShowPasswordReset}
                                onClick={() => setIsViewingResetToken(true)}
                                tabIndex={0}
                            >
                                <UnlockOutlined /> &nbsp; Reset user password
                            </Menu.Item>
                            <Menu.Item onClick={onDeleteEntity} tabIndex={0}>
                                <DeleteOutlined /> &nbsp;Delete
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <MenuIcon fontSize={20} tabIndex={0} />
                </Dropdown>
            </ButtonGroup>
            <ViewResetTokenModal
                visible={isViewingResetToken}
                userUrn={user.urn}
                username={user.username}
                onClose={() => setIsViewingResetToken(false)}
            />
        </List.Item>
    );
}
