import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import TabToolbar from '../../../components/styled/TabToolbar';

type DescriptionEditorToolbarProps = {
    disableSave: boolean;
    onClose: () => void;
    onSave: () => void;
};

export const DescriptionEditorToolbar = ({ disableSave, onClose, onSave }: DescriptionEditorToolbarProps) => {
    return (
        <TabToolbar>
            <Button className="mr-2" type="default" onClick={onClose}>
                Back
            </Button>
            <Button
                data-testid="description-editor-save-button"
                onClick={onSave}
                disabled={disableSave}
                aria-label="Save"
            >
                <CheckOutlined /> Save
            </Button>
        </TabToolbar>
    );
};
