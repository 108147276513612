import React from 'react';
import styled from 'styled-components';

const SpanStyled = styled.div`
    word-break: break-word;
`;
const TextColumn = ({ value = '' }: { value: string }) => {
    return (
        <SpanStyled className="f-truncate-two-line" title={value || '-'}>
            {value || '-'}
        </SpanStyled>
    );
};

export default TextColumn;
