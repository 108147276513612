import React, { ReactNode, useState } from 'react';
import { Divider, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
    GlobalTags,
    Owner,
    GlossaryTerms,
    SearchInsight,
    Container,
    ParentContainersResult,
    Maybe,
    CorpUser,
    Deprecation,
    Domain,
    EntityPath,
    DataProduct,
    Health,
    Entity,
} from '../../types.generated';
import TagTermGroup from '../shared/tags/TagTermGroup';
import { ANTD_GRAY } from '../entity/shared/constants';
import NoMarkdownViewer from '../entity/shared/components/styled/StripMarkdownText';
import { getNumberWithOrdinal } from '../entity/shared/utils';
import { useEntityData } from '../entity/shared/EntityContext';
import PlatformContentView from '../entity/shared/containers/profile/header/PlatformContent/PlatformContentView';
import useContentTruncation from '../shared/useContentTruncation';
import EntityCount from '../entity/shared/containers/profile/header/EntityCount';
import { ExpandedActorGroup } from '../entity/shared/components/styled/ExpandedActorGroup';
import { DeprecationPill } from '../entity/shared/components/styled/DeprecationPill';
import { GovernanceFlagAspectBatch } from '../entity/shared/components/styled/GovernanceFlagAspectBatch';
import { GovernanceStatusPill } from '../entity/shared/components/styled/GovernanceStatusPill';

import { PreviewType } from '../entity/Entity';
import ExternalUrlButton from '../entity/shared/ExternalUrlButton';
import EntityPaths from './EntityPaths/EntityPaths';
import { DataProductLink } from '../shared/tags/DataProductLink';
import { EntityHealth } from '../entity/shared/containers/profile/header/EntityHealth';
import SearchTextHighlighter from '../search/matches/SearchTextHighlighter';
import { getUniqueOwners } from './utils';

interface Props {
    name: string;
    logoUrl?: string;
    logoComponent?: JSX.Element;
    url: string;
    description?: string;
    type?: string;
    platform?: string;
    qualifier?: string | null;
    tags?: GlobalTags;
    owners?: Array<Owner> | null;
    snippet?: React.ReactNode;
    insights?: Array<SearchInsight> | null;
    glossaryTerms?: GlossaryTerms;
    dataTestID?: string;
    onClick?: () => void;
}

const PreviewContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        display: block;
    }
`;

const LeftColumn = styled.div<{ expandWidth: boolean }>`
    width: 80%;
    padding-right: ${(props) => (props.expandWidth ? '0' : '8px')};

    @media (max-width: 768px) {
        width: 100%;
        padding-right: 0;
    }
`;

const RightColumn = styled.div`
    display: flex;
    flex-shrink: 1;
`;

const TitleContainer = styled.div`
    margin-bottom: 5px;
    line-height: 30px;
`;

const EntityTitle = styled(Typography.Text)<{ $titleSizePx?: number }>`
    display: block;
    &&&:hover {
        text-decoration: underline;
    }

    &&& {
        margin-right 8px;
        font-size: ${(props) => props.$titleSizePx || 16}px;
        font-weight: 600;
        vertical-align: middle;
    }
`;

const PlatformText = styled(Typography.Text)``;

const EntityTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CardEntityTitle = styled(EntityTitle)`
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PlatformDivider = styled.div`
    display: inline-block;
    padding-left: 10px;
    margin-right: 10px;
    border-right: 1px solid #e9e9ea;
    height: 21px;
    vertical-align: text-top;
`;

const DescriptionContainer = styled.div``;

const TagContainer = styled.div`
    display: inline-flex;
    align-items: center;
    margin-left: 0px;
    margin-top: 3px;
    width: 100%;
    flex-wrap: wrap;
`;

const TagSeparator = styled.div`
    margin: 0px 4px 0;
    height: 17px;
    border-right: 1px solid #cccccc;
`;

const InsightContainer = styled.div`
    margin-top: 12px;
`;

const InsightsText = styled(Typography.Text)`
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: ${ANTD_GRAY[7]};
`;

const InsightIconContainer = styled.span`
    margin-right: 4px;
`;

const UserListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: right;
    margin-right: 8px;
`;

const UserListDivider = styled(Divider)`
    padding: 4px;
    height: auto;
`;

const UserListTitle = styled(Typography.Text)`
    text-align: right;
    margin-bottom: 10px;
    padding-right: 12px;

    @media (max-width: 768px) {
        text-align: left;
    }
`;

const DeletedDataset = styled(Typography.Text)`
    &&& span {
        color: #8f9094 !important;
    }
`;

interface Props {
    name: string;
    urn: string;
    logoUrl?: string;
    logoComponent?: JSX.Element;
    url: string;
    description?: string;
    type?: string;
    typeIcon?: JSX.Element;
    platform?: string;
    platformInstanceId?: string;
    platforms?: Maybe<string | undefined>[];
    logoUrls?: Maybe<string | undefined>[];
    qualifier?: string | null;
    tags?: GlobalTags;
    owners?: Array<Owner> | null;
    deprecation?: Deprecation | null;
    topUsers?: Array<CorpUser> | null;
    externalUrl?: string | null;
    entityTitleSuffix?: React.ReactNode;
    entityType?: string;
    subHeader?: React.ReactNode;
    snippet?: React.ReactNode;
    insights?: Array<SearchInsight> | null;
    glossaryTerms?: GlossaryTerms;
    governanceStatus?: any;
    governanceFlag?: any;
    container?: Container;
    domain?: Domain | undefined | null;
    dataProduct?: DataProduct | undefined | null;
    entityCount?: number;
    displayAssetCount?: boolean;
    dataTestID?: string;
    titleSizePx?: number;
    onClick?: () => void;
    // this is provided by the impact analysis view. it is used to display
    // how the listed node is connected to the source node
    degree?: number;
    parentContainers?: ParentContainersResult | null;
    parentEntities?: Entity[] | null;
    previewType?: Maybe<PreviewType>;
    paths?: EntityPath[];
    health?: Health[];
    exists?: boolean;
}

export default function DefaultPreviewCard({
    name,
    urn,
    logoUrl,
    logoComponent,
    url,
    description,
    type,
    typeIcon,
    platform,
    platformInstanceId,
    // TODO(Gabe): support qualifier in the new preview card
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    qualifier,
    tags,
    owners,
    topUsers,
    subHeader,
    snippet,
    insights,
    glossaryTerms,
    governanceFlag,
    governanceStatus,
    domain,
    dataProduct,
    container,
    deprecation,
    entityCount,
    displayAssetCount,
    titleSizePx,
    dataTestID,
    externalUrl,
    entityTitleSuffix,
    entityType,
    onClick,
    degree,
    parentContainers,
    parentEntities,
    platforms,
    logoUrls,
    previewType,
    exists,
    paths,
    health,
}: Props) {
    // sometimes these lists will be rendered inside an entity container (for example, in the case of impact analysis)
    // in those cases, we may want to enrich the preview w/ context about the container entity
    const { entityData } = useEntityData();

    const insightViews: Array<ReactNode> = [
        ...(insights?.map((insight) => (
            <>
                <InsightIconContainer>{insight.icon}</InsightIconContainer>
                <InsightsText>{insight.text}</InsightsText>
            </>
        )) || []),
    ];
    const hasGlossaryTerms = !!glossaryTerms?.terms?.length;
    const hasTags = !!tags?.tags?.length;
    if (snippet) {
        insightViews.push(snippet);
    }

    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    const { contentRef, isContentTruncated } = useContentTruncation(container);

    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const shouldShowRightColumn = (topUsers && topUsers.length > 0) || (owners && owners.length > 0);
    const uniqueOwners = getUniqueOwners(owners);
    const isGovernanceStatus = governanceStatus && governanceStatus?.status !== 'None';

    // Dynamically render the tags component based on the existence of the tags
    const renderTagsComponent = [
        { component: dataProduct && <DataProductLink dataProduct={dataProduct} /> },
        { component: domain && <TagTermGroup domain={domain} maxShow={3} /> },
        {
            component: governanceFlag?.flags?.length && (
                <GovernanceFlagAspectBatch urn={urn} aspectFlag={governanceFlag} closable={false} />
            ),
        },
        { component: hasGlossaryTerms && <TagTermGroup uneditableGlossaryTerms={glossaryTerms} maxShow={3} /> },
        { component: hasTags && <TagTermGroup uneditableTags={tags} maxShow={3} /> },
    ];

    const activeTags = renderTagsComponent.filter(({ component }) => component);

    // Sanitize a string by replacing &nbsp; with regular spaces and check if it contains only spaces
    function sanitizeAndCheckSpaces(str) {
        // Replace &nbsp; with regular spaces
        const replacedStr = str.replace(/&nbsp;/g, '');
        // Trim the string and check if it's empty
        return replacedStr.length < 1 ? replacedStr : str;
    }

    const LinkWrapper = ({ children }: { children: React.ReactNode }) => {
        if (!exists && entityType === 'DATASET') {
            return (
                <DeletedDataset className="f-color-high-contrast">
                    <Tooltip title="This dataset is no longer available.">{children}</Tooltip>
                </DeletedDataset>
            );
        }
        return url ? <Link to={url}>{children}</Link> : <>{children}</>;
    };

    return (
        <PreviewContainer data-testid={dataTestID} onMouseDown={onPreventMouseDown}>
            <LeftColumn className="f-left-column" expandWidth={!shouldShowRightColumn}>
                <TitleContainer>
                    <PlatformContentView
                        platformName={platform}
                        platformLogoUrl={logoUrl}
                        platformNames={platforms}
                        platformLogoUrls={logoUrls}
                        entityLogoComponent={logoComponent}
                        instanceId={platformInstanceId}
                        typeIcon={typeIcon}
                        entityType={type}
                        parentContainers={parentContainers?.containers}
                        parentEntities={parentEntities}
                        parentContainersRef={contentRef}
                        areContainersTruncated={isContentTruncated}
                    />
                    <EntityTitleContainer className="f-layout-wrap f-layout-wrap-xs">
                        <LinkWrapper>
                            {previewType === PreviewType.HOVER_CARD ? (
                                <CardEntityTitle
                                    onClick={onClick}
                                    className="f-text-big-content f-color-dark-black-s80"
                                >
                                    {name || ' '}
                                </CardEntityTitle>
                            ) : (
                                <EntityTitle
                                    className="f-text-big-content f-color-dark-black-s80 f-word-break-break-word"
                                    onClick={onClick}
                                    $titleSizePx={titleSizePx}
                                >
                                    <SearchTextHighlighter field="name" text={name || ''} />
                                </EntityTitle>
                            )}
                        </LinkWrapper>
                        {deprecation?.deprecated && (
                            <DeprecationPill deprecation={deprecation} urn="" showUndeprecate={false} />
                        )}
                        {isGovernanceStatus && <GovernanceStatusPill governance={governanceStatus} />}

                        {health && health.length > 0 ? <EntityHealth baseUrl={url} health={health} /> : null}
                        {externalUrl && (
                            <ExternalUrlButton
                                externalUrl={externalUrl}
                                platformName={platform}
                                entityUrn={urn}
                                entityType={type}
                            />
                        )}
                        {entityTitleSuffix}
                    </EntityTitleContainer>
                    {degree !== undefined && degree !== null && (
                        <Tooltip
                            title={`This entity is a ${getNumberWithOrdinal(degree)} degree connection to ${
                                entityData?.name || 'the source entity'
                            }`}
                        >
                            <PlatformText>{getNumberWithOrdinal(degree)}</PlatformText>
                        </Tooltip>
                    )}
                    {!!degree && entityCount && <PlatformDivider />}
                    <EntityCount entityCount={entityCount} displayAssetsText={displayAssetCount} />
                </TitleContainer>
                {paths && paths.length > 0 && <EntityPaths paths={paths} resultEntityUrn={urn || ''} />}
                {description && description.length > 0 && (
                    <DescriptionContainer className="f-text-medium-content f-color-dark-black-s80">
                        <NoMarkdownViewer
                            limit={descriptionExpanded ? undefined : 250}
                            shouldWrap={previewType === PreviewType.HOVER_CARD}
                            readMore={
                                previewType === PreviewType.HOVER_CARD ? (
                                    <Typography.Link
                                        onClickCapture={(e) => {
                                            onPreventMouseDown(e);
                                            setDescriptionExpanded(!descriptionExpanded);
                                        }}
                                    >
                                        {descriptionExpanded ? 'Show Less' : 'Show More'}
                                    </Typography.Link>
                                ) : undefined
                            }
                            customRender={(text) => <SearchTextHighlighter field="description" text={text} />}
                        >
                            {sanitizeAndCheckSpaces(description)}
                        </NoMarkdownViewer>
                    </DescriptionContainer>
                )}
                {activeTags?.length > 0 && (
                    <TagContainer className="f-layout-wrap-xs">
                        {activeTags.map(({ component }, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={index}>
                                {component && component}
                                {activeTags.length > 1 && activeTags.length - 1 !== index && component && (
                                    <TagSeparator className="f-hidden-xs" />
                                )}
                            </React.Fragment>
                        ))}
                    </TagContainer>
                )}
                {subHeader}
                {insightViews.length > 0 && (
                    <InsightContainer>
                        {insightViews.map((insightView, index) => (
                            <span>
                                {insightView}
                                {index < insightViews.length - 1 && <PlatformDivider />}
                            </span>
                        ))}
                    </InsightContainer>
                )}
            </LeftColumn>
            {shouldShowRightColumn && (
                <RightColumn key="right-column">
                    {topUsers && topUsers?.length > 0 && (
                        <>
                            <UserListContainer>
                                <UserListTitle strong>Top Users</UserListTitle>
                                <div>
                                    <ExpandedActorGroup actors={topUsers} max={2} />
                                </div>
                            </UserListContainer>
                        </>
                    )}
                    {(topUsers?.length || 0) > 0 && (uniqueOwners?.length || 0) > 0 && (
                        <UserListDivider type="vertical" />
                    )}
                    {uniqueOwners && uniqueOwners?.length > 0 && (
                        <UserListContainer>
                            <UserListTitle strong>Owners</UserListTitle>
                            <div>
                                <ExpandedActorGroup actors={uniqueOwners.map((owner) => owner.owner)} max={2} />
                            </div>
                        </UserListContainer>
                    )}
                </RightColumn>
            )}
        </PreviewContainer>
    );
}
