import { Select, SelectProps, Spin } from 'antd';
import React, { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useGetSearchResultsLazyQuery } from '../../graphql/search.generated';
import utilService from '../../utils/util.service';
import { CorpUser, Entity, EntityType } from '../../types.generated';
import { useEntityRegistry } from '../useEntityRegistry';
import { OwnerLabel } from '../shared/OwnerLabel';

const { debounce } = utilService;

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

interface IOwnerLookup extends SelectProps {
    filterFieldRef: any;
    handleFieldRef: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const OwnerLookup = (props: IOwnerLookup) => {
    const entityRegistry = useEntityRegistry();
    const [userSearch, { data: userSearchData, loading }] = useGetSearchResultsLazyQuery();
    const userSearchDebounce = debounce(userSearch, 500);

    const users = userSearchData?.search?.searchResults || [];

    useEffect(() => {
        userSearch({
            variables: {
                input: {
                    type: EntityType.CorpUser,
                    query: '*',
                    start: 0,
                    count: 5,
                },
            },
        });
    }, [userSearch]);

    const onSearch = (text: string) => {
        userSearchDebounce({
            variables: {
                input: {
                    type: EntityType.CorpUser,
                    query: text || '*',
                    start: 0,
                    count: 5,
                },
            },
        });
    };

    const RenderOptions = ({ entity }: { entity: Entity }) => {
        const avatarUrl =
            (entity.type === EntityType.CorpUser && (entity as CorpUser).editableProperties?.pictureLink) || undefined;
        const displayName = entityRegistry.getDisplayName(entity.type, entity);

        return {
            label: <OwnerLabel name={displayName} avatarUrl={avatarUrl} type={entity.type} />,
            value: entity.urn,
            key: entity.urn,
        };
    };

    return (
        <Select
            style={{ width: '100%' }}
            placeholder="Select the User"
            allowClear
            showSearch
            ref={props?.filterFieldRef}
            onSearch={onSearch}
            onKeyDown={props?.handleFieldRef}
            className="f-filter-dropdown-field"
            loading={loading}
            options={users?.map(RenderOptions)}
            notFoundContent={loading ? <Spin indicator={antIcon} style={{ width: '100%' }} /> : null}
            {...props}
        />
    );
};

export default OwnerLookup;
