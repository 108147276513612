import React from 'react';
import { message } from 'antd';
import { useLocation, useHistory } from 'react-router';
import { useEntityData } from '../EntityContext';
import { useBatchDeleteDatasetsMutation } from '../../../../graphql/dataset.generated';
import ConfirmationModal from '../../../shared/modals/ConfirmationModal';

interface DeleteDatasetModalProps {
    urn: string;
    onClose: () => void;
}

/**
 * Modal component for confirming dataset deletion.
 * Renders a modal dialog with a confirmation message and handles the deletion of a dataset.
 * */
function DeleteDatasetModal({ urn, onClose }: DeleteDatasetModalProps) {
    const { entityData } = useEntityData();
    const location = useLocation();
    const history = useHistory();
    const rootPath = location.pathname;
    const [batchDeleteDatasetsMutation] = useBatchDeleteDatasetsMutation();

    // Handles the deletion of a dataset from the detail page.
    const handleDelete = () => {
        try {
            const variables = {
                input: {
                    currentPath: rootPath?.split('/dataset')[1],
                    chosenPaths: [entityData?.name || ''],
                    datasetUrns: [urn],
                },
            };

            batchDeleteDatasetsMutation({ variables })
                .then(() => {
                    message.success({ content: 'Initiating Dataset Deletion Process', duration: 3 });
                    history.push('/browse/dataset');
                })
                .catch(() => message.error({ content: `Failed to delete the datasets`, duration: 3 }));

            onClose();
        } catch (error) {
            message.error({ content: `Failed to delete the datasets`, duration: 3 });
        }
    };

    return (
        // Display confirmation modal when the button is clicked
        <ConfirmationModal
            title="Delete Dataset"
            okText="Delete"
            cancelText="Cancel"
            visible
            isLoading={false}
            onClick={handleDelete}
            onClose={onClose}
            confirmationText={` Are you sure you want to delete ${entityData?.name}?\n Please note that this action cannot be undone.`}
        />
    );
}

export default DeleteDatasetModal;
