import React from 'react';
import { Modal, Button, Typography } from 'antd';

interface ConfirmationModalProps {
    title: string;
    okText: string;
    cancelText: string;
    visible: boolean;
    isLoading?: boolean;
    confirmationText: string;
    onClose: () => void;
    onClick: () => void;
}

/**
 * Modal component for confirming dataset deletion.
 * Renders a modal dialog with a confirmation message and handles the deletion of a dataset.
 * */
function ConfirmationModal({
    title,
    okText,
    visible,
    cancelText,
    onClose,
    onClick,
    confirmationText,
    isLoading,
}: ConfirmationModalProps) {
    return (
        <>
            {/* Display confirmation modal when the button is clicked */}
            <Modal
                centered
                visible={visible}
                title={title}
                okText={okText}
                cancelText={cancelText}
                onCancel={onClose}
                closable={false}
                className="f-borderless-modal"
                style={{ maxWidth: 400 }}
                footer={[
                    <Button key="submit" onClick={onClick} danger disabled={isLoading}>
                        {okText || ''}
                    </Button>,
                    <Button key="cancel" onClick={onClose}>
                        {cancelText || ''}
                    </Button>,
                ]}
            >
                <Typography.Paragraph className="mb-0" style={{ textAlign: 'center' }}>
                    {confirmationText || ''}
                </Typography.Paragraph>
            </Modal>
        </>
    );
}

export default ConfirmationModal;
