import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Input, Modal, Typography, Form, Select, Spin, notification, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

import { useSearchAcrossEntitiesWithSearchAfterQuery } from '../../../../../../graphql/search.generated';
import { AndFilterInput, EntityType } from '../../../../../../types.generated';

import { getSearchCsvDownloadHeader, transformResultsToCsvRow } from './downloadAsCsvUtil';
import { downloadRowsAsCsv } from '../../../../../search/utils/csvUtils';
import { useEntityRegistry } from '../../../../../useEntityRegistry';
import { useEntityData } from '../../../EntityContext';
import analytics, { EventType } from '../../../../../analytics';

type Props = {
    downloadType: any;
    filters: AndFilterInput[];
    query: string;
    entityFilters: EntityType[];
    totalResults?: number;
    setIsDownloadingCsv: (isDownloadingCsv: boolean) => any;
    showDownloadAsCsvModal: boolean;
    setShowDownloadAsCsvModal: (showDownloadAsCsvModal: boolean) => any;
};

const StyledItem = styled(Form.Item)`
    margin-bottom: 0;
`;

const SEARCH_PAGE_SIZE_FOR_DOWNLOAD = 100;

export default function DownloadAsCsvModal({
    filters,
    query,
    entityFilters,
    totalResults,
    downloadType,
    setIsDownloadingCsv,
    showDownloadAsCsvModal,
    setShowDownloadAsCsvModal,
}: Props) {
    const { entityData: entitySearchIsEmbeddedWithin } = useEntityData();
    const { refetch: downloadSearchResults } = useSearchAcrossEntitiesWithSearchAfterQuery({
        skip: true,
    });
    const location = useLocation();
    const [form] = Form.useForm();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const initialValues = {
        name: entitySearchIsEmbeddedWithin ? `${entitySearchIsEmbeddedWithin.name}_impact` : 'results',
        'file-format': 'csv',
    };

    const entityRegistry = useEntityRegistry();

    const openNotification = () => {
        notification.info({
            message: 'Preparing Download',
            description: totalResults
                ? `Creating CSV with ${totalResults} entities to download`
                : 'Creating CSV to download',
            placement: 'bottomRight',
            duration: null,
            icon: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
        });
    };

    const closeNotification = () => {
        setTimeout(() => {
            notification.destroy();
        }, 3000);
    };

    const showFailedDownloadNotification = () => {
        notification.destroy();
        notification.error({
            message: 'Download Failed',
            description: 'The CSV file could not be downloaded',
            placement: 'bottomRight',
            duration: 3,
        });
    };

    const triggerCsvDownload = (filename) => {
        setIsDownloadingCsv(true);
        openNotification();

        let accumulatedResults: string[][] = [];

        analytics.event({
            type: EventType.DownloadAsCsvEvent,
            query,
            entityUrn: entitySearchIsEmbeddedWithin?.urn,
            path: location.pathname,
        });

        function fetchNextPage(searchAfterParam: any = null) {
            downloadSearchResults({
                input: {
                    searchInput: {
                        types: entityFilters,
                        query,
                        start: 0,
                        count: SEARCH_PAGE_SIZE_FOR_DOWNLOAD,
                        orFilters: filters,
                    },
                    searchAfter: searchAfterParam,
                },
            })
                .then((res) => {
                    const { searchAcrossEntitiesWithSearchAfter: searchAcrossEntities } = res?.data || {};

                    console.log(`Fetched search after ${searchAcrossEntities?.searchAfter?.entityId}`);

                    if (searchAcrossEntities && searchAcrossEntities?.results.searchResults?.length > 0) {
                        accumulatedResults = [
                            ...accumulatedResults,
                            ...transformResultsToCsvRow(
                                searchAcrossEntities?.results.searchResults || [],
                                entityRegistry,
                            ),
                        ];

                        const payload = searchAcrossEntities?.searchAfter
                            ? { entityId: searchAcrossEntities?.searchAfter?.entityId }
                            : null;

                        fetchNextPage(payload);
                    } else {
                        setIsDownloadingCsv(false);
                        closeNotification();
                        downloadRowsAsCsv(
                            getSearchCsvDownloadHeader(searchAcrossEntities?.results.searchResults[0]),
                            accumulatedResults,
                            filename,
                        );
                    }
                })
                .catch((_) => {
                    setIsDownloadingCsv(false);
                    showFailedDownloadNotification();
                });
        }
        fetchNextPage();
    };

    const onFinish = (values: any) => {
        setShowDownloadAsCsvModal(false);

        const { checkDownloadType }: any = downloadType || {};

        if (checkDownloadType) {
            if (checkDownloadType?.type) {
                triggerCsvDownload(`${values.name}.${values['file-format']}`);
            } else {
                message.error('Something went wrong');
            }
        }
    };
    return (
        <Modal
            centered
            onCancel={() => setShowDownloadAsCsvModal(false)}
            title="Download as..."
            visible={showDownloadAsCsvModal}
            footer={
                <>
                    <Button onClick={() => setShowDownloadAsCsvModal(false)} type="text">
                        Close
                    </Button>
                    <Button
                        form="download-as"
                        key="submit"
                        htmlType="submit"
                        data-testid="csv-modal-download-button"
                        disabled={buttonDisabled}
                    >
                        Download
                    </Button>
                </>
            }
        >
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                id="download-as"
                placeholder="datahub.csv"
                initialValues={initialValues}
                onFieldsChange={() => {
                    setButtonDisabled(form.getFieldsError().filter(({ errors }) => errors.length).length > 0);
                }}
            >
                <Form.Item label={<Typography.Text strong>Name</Typography.Text>}>
                    <StyledItem
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'required',
                            },
                        ]}
                    >
                        <Input placeholder="datahub" />
                    </StyledItem>
                </Form.Item>
                <Form.Item label={<Typography.Text strong>Format Type</Typography.Text>}>
                    <StyledItem name="file-format">
                        <Select
                            aria-label="Select file format"
                            getPopupContainer={(triggerNode) => triggerNode.parentNode} // Control dropdown container
                        >
                            <Select.Option value="csv" tabIndex={0}>
                                csv
                            </Select.Option>
                        </Select>
                    </StyledItem>
                </Form.Item>
            </Form>
        </Modal>
    );
}
