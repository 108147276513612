import React from 'react';
import styled from 'styled-components';
import { Card, Button, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { ReactComponent as DownloadOutlined } from '../../../images/downloaded-outline.svg';
import { ReactComponent as DeleteOutlined } from '../../../images/delete-outline.svg';
import { ReactComponent as DragIndicator } from '../../../images/drag-indicator.svg';

interface Props {
    type: string;
    selectedItems: string[];
    exportCsv: () => void;
    confirmDatasetDeletion?: () => void;
    setSelectedItems: (items: string[]) => void;
}

const Flex = styled.div`
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    max-width: 100%;
`;

const DeleteButton = styled(Button)`
    background-color: white !important;
    span {
        color: #d96758 !important;
    }
`;

const ExportCSVButton = styled(Button)`
    background-color: white !important;
    span {
        color: #5f767c !important;
    }
`;

const Layout = styled.div`
    display: flex;
    gap: 8px;
`;

export const BulkAction = ({ selectedItems, type, exportCsv, confirmDatasetDeletion, setSelectedItems }: Props) => {
    const isDataset = type === 'dataset';

    return selectedItems?.length ? (
        <Card className="f-card f-bulk-action-bar">
            <Layout className="pa-2">
                <Flex>
                    <Flex className="shrink">
                        <DragIndicator height={20} width={20} color="#BCBDBF" />
                    </Flex>
                    <Layout>
                        <Flex className="f-text-big-content f-color-blue-s100 f-text-semibold shrink">
                            <Tag className="grey-tag">
                                <span>{selectedItems.length}</span>
                            </Tag>
                        </Flex>
                        <Flex className="f-text-big-content f-color-dark-black-s80 flex">Selected</Flex>
                    </Layout>
                </Flex>

                <Flex className="shrink">
                    <ExportCSVButton
                        onClick={exportCsv}
                        icon={<DownloadOutlined style={{ width: 16 }} color="#5F767C" />}
                    >
                        Export
                    </ExportCSVButton>
                </Flex>

                {isDataset && (
                    <Flex className="shrink">
                        <DeleteButton
                            danger
                            onClick={confirmDatasetDeletion}
                            icon={<DeleteOutlined color="#d96758" style={{ width: 10 }} />}
                        >
                            Delete Datasets
                        </DeleteButton>
                    </Flex>
                )}
                <Flex className="shrink">
                    <Button
                        type="text"
                        shape="circle"
                        onClick={() => setSelectedItems([])}
                        icon={<CloseOutlined style={{ width: 14 }} />}
                    />
                </Flex>
            </Layout>
        </Card>
    ) : (
        <></>
    );
};
