import React, { useEffect, createContext, useContext, useState } from 'react';

const ACCESSIBILITY_LOADED = 'ACCESSIBILITY_LOADED';
const AccessibilityContext = createContext(false);

// This component listens for messages from the parent window to toggle high contrast mode.
const AccessibilityHandler: React.FC = ({ children }: any) => {
    const [isAccessibilityEnabled, setIsAccessibilityEnabled] = useState(false);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // Check if the message is from the parent window
            if (event.data.type === ACCESSIBILITY_LOADED) {
                const { isAccessibilityOn } = event.data;

                // Add high contrast class to body if accessibility is enabled
                if (isAccessibilityOn) {
                    document.body.classList.add('f-high-contrast');
                } else {
                    document.body.classList.remove('f-high-contrast');
                }

                // Set the accessibility state
                setIsAccessibilityEnabled(isAccessibilityOn);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    // Provide the accessibility state to the children
    return <AccessibilityContext.Provider value={isAccessibilityEnabled}>{children}</AccessibilityContext.Provider>;
};

export default AccessibilityHandler;

// Custom hook to use accessibility context
export const useAccessibility = () => {
    return useContext(AccessibilityContext);
};
