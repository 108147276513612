import React from 'react';
import { Card, Row, Space, Typography, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { singularizeCollectionName } from '../entity/shared/utils';
import { ReactComponent as FolderOutlined } from '../../images/folder-outline.svg';

const styles = {
    row: { padding: '32px 16px 32px 24px' },
    title: { margin: 0 },
};

const LinkBlock = styled(Link)`
    display: block;
    position: relative;
    margin-bottom: 2px;

    &:hover,
    &:focus {
        z-index: 2;
    }
`;

const ResultCard = styled(Card)`
    && {
        border-color: ${(props) => props.theme.styles['border-color-base']};
    }
    &&:hover {
        box-shadow: none;
    }
`;

export interface BrowseResultProps {
    url: string;
    name: string;
    count?: number | undefined;
    type: string;
    selectedItems: string[];
    onClick?: () => void;
    handleItemCheck: (itemName: string, event) => void;
}

export default function BrowseResultCard({
    url,
    count,
    name,
    type,
    selectedItems,
    onClick,
    handleItemCheck,
}: BrowseResultProps) {
    let displayType = type;
    if (count === 1) {
        displayType = singularizeCollectionName(type);
    }
    return (
        <LinkBlock to={url} onClick={onClick}>
            <ResultCard hoverable aria-label={`${name} ${count} ${displayType}`}>
                <Row style={styles.row} justify="space-between" align="middle" className="f-pa-4-xs">
                    <Space size="middle" align="center" className="f-align-start-xs">
                        <Checkbox
                            aria-label={name}
                            checked={selectedItems.includes(name)}
                            onClick={(e) => handleItemCheck(name, e)}
                        />
                        <FolderOutlined className="f-icon-size-md f-color-dark-black-s50" width={16} />
                        <Typography.Title
                            style={styles.title}
                            className="f-text-big-content f-text-semibold f-color-dark-black-s80"
                        >
                            {name}
                        </Typography.Title>
                    </Space>
                    <Space className="f-width-100-xs f-justify-between-xs f-mt-2-xs">
                        {count && (
                            <Typography.Text className="f-text-big-content f-color-dark-black-s50">
                                {count} {displayType}
                            </Typography.Text>
                        )}
                        <RightOutlined className="f-icon-size-xs f-color-dark-black-s50" />
                    </Space>
                </Row>
            </ResultCard>
        </LinkBlock>
    );
}
