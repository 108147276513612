import React, { useEffect, useState } from 'react';
import { Alert, Button, message, Space, Typography, Collapse, Select } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getRecipeJson } from './RecipeForm/TestConnection/TestConnectionButton';
import { StyledCollapse, SectionHeader } from './RecipeForm/RecipeForm';
import { StyledFormItem } from './RecipeForm/SecretField/SecretField';
import { IngestionSourceBuilderStep } from './steps';
import RecipeBuilder from './RecipeBuilder';
import { YamlEditor } from './YamlEditor';

import { useApplianceListQuery } from '../../../../graphql/appliance.generated';
import { getPlaceholderRecipe, getSourceConfigs, jsonToYaml } from '../utils';
import { CONNECTORS_WITH_FORM } from './RecipeForm/constants';
import { ANTD_GRAY } from '../../../entity/shared/constants';
import { APPLIANCE_INGESTION } from './RecipeForm/common';
import { StepProps } from './types';

const LOOKML_DOC_LINK = 'https://datahubproject.io/docs/generated/ingestion/sources/looker#module-lookml';

const Section = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
`;

const BorderedSection = styled(Section)`
    border: solid ${ANTD_GRAY[4]} 0.5px;
`;

const SelectTemplateHeader = styled(Typography.Title)`
    && {
        margin-bottom: 8px;
    }
`;

const ControlsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
`;

interface ApplianceProps {
    appliance: {
        name: string;
        label: string;
        tooltip: string;
        options?: any[];
        placeholder: string;
    };
}

/**
 * The step for defining a recipe
 */
export const DefineRecipeStep = ({ state, updateState, goTo, prev, ingestionSources }: StepProps) => {
    const existingRecipeJson = state.config?.recipe;
    const existingApplianceId = state?.applianceId;
    const existingRecipeYaml = existingRecipeJson && jsonToYaml(existingRecipeJson);
    const { type } = state;
    const sourceConfigs = getSourceConfigs(ingestionSources, type as string);
    const placeholderRecipe = getPlaceholderRecipe(ingestionSources, type);

    const [stagedRecipeYml, setStagedRecipeYml] = useState(existingRecipeYaml || placeholderRecipe);
    const [stagedRecipeName, setStagedRecipeName] = useState(state.name);

    const [applianceId, setApplianceId] = useState(existingApplianceId || '');

    // This query fetch the appliance list for dropdown
    const { data: applianceData } = useApplianceListQuery({});

    useEffect(() => {
        if (existingApplianceId) {
            setApplianceId(existingApplianceId);
        }
    }, [existingApplianceId]);

    useEffect(() => {
        if (existingRecipeYaml) {
            setStagedRecipeName(state.name);
            setStagedRecipeYml(existingRecipeYaml);
        }
    }, [existingRecipeYaml, state.name]);

    const [stepComplete, setStepComplete] = useState(false);

    const isEditing: boolean = prev === undefined;
    const displayRecipe = stagedRecipeYml || placeholderRecipe;
    const sourceDisplayName = sourceConfigs?.displayName;
    const sourceDocumentationUrl = sourceConfigs?.docsUrl;

    // TODO: Delete LookML banner specific code
    const isSourceLooker: boolean = sourceConfigs?.name === 'looker';
    const [showLookerBanner, setShowLookerBanner] = useState(isSourceLooker && !isEditing);

    useEffect(() => {
        if (stagedRecipeYml && stagedRecipeYml.length > 0 && !showLookerBanner) {
            setStepComplete(true);
        }
    }, [stagedRecipeYml, showLookerBanner]);

    const onClickNext = () => {
        const recipeJson = getRecipeJson(stagedRecipeYml);
        if (!recipeJson) return;

        if (!JSON.parse(recipeJson).source?.type) {
            message.warning({
                content: `Please add valid ingestion type`,
                duration: 3,
            });
            return;
        }

        const newState = {
            ...state,
            config: {
                ...state.config,
                recipe: recipeJson,
            },
            applianceId,
            type: JSON.parse(recipeJson).source.type,
        };
        updateState(newState);

        // TODO: Need to replace NAME_SOURCE with CREATE_SCHEDULE when scheduling is enabled
        goTo(IngestionSourceBuilderStep.NAME_SOURCE);
    };

    if (type && CONNECTORS_WITH_FORM.has(type)) {
        return (
            <RecipeBuilder
                key={stagedRecipeName}
                state={state}
                isEditing={isEditing}
                applianceId={applianceId}
                displayRecipe={displayRecipe}
                sourceConfigs={sourceConfigs}
                setStagedRecipe={setStagedRecipeYml}
                setApplianceId={setApplianceId}
                onClickNext={onClickNext}
                goToPrevious={prev}
            />
        );
    }

    // Making the appliance list format compatible with the select component.
    APPLIANCE_INGESTION.setOptions(applianceData?.applianceList);

    // This component is used to select the pod for the recipe
    const PodSelect = ({ appliance }: ApplianceProps) => {
        return (
            <StyledCollapse defaultActiveKey="0">
                <Collapse.Panel forceRender header={<SectionHeader icon={<ApiOutlined />} text="Pods" />} key="0">
                    <StyledFormItem name={appliance.name} label={appliance.label} tooltip={appliance.tooltip}>
                        {appliance.options && (
                            <Select
                                value={applianceId}
                                aria-label="Select Pod"
                                defaultValue={applianceId || undefined}
                                placeholder={appliance.placeholder}
                                onChange={(value) => setApplianceId(value)}
                                getPopupContainer={(triggerNode) => triggerNode.parentNode} // Control dropdown container
                            >
                                {appliance.options.map((option: any) => (
                                    <Select.Option value={option.value} tabIndex={0}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </StyledFormItem>
                </Collapse.Panel>
            </StyledCollapse>
        );
    };

    return (
        <>
            <Section>
                <SelectTemplateHeader level={5}>Choose Pod</SelectTemplateHeader>
                <PodSelect appliance={APPLIANCE_INGESTION} />
            </Section>
            <Section>
                <SelectTemplateHeader level={5}>Configure {sourceDisplayName} Recipe</SelectTemplateHeader>
                {showLookerBanner && (
                    <Alert
                        type="warning"
                        banner
                        message={
                            <>
                                <big>
                                    <i>
                                        <b>You must acknowledge this message to proceed!</b>
                                    </i>
                                </big>
                                <br />
                                <br />
                                To get complete Looker metadata integration (including Looker views and lineage to the
                                underlying warehouse tables), you must <b>also</b> use the{' '}
                                <a href={LOOKML_DOC_LINK} target="_blank" rel="noopener noreferrer">
                                    DataCatalog lookml module
                                </a>
                                .
                                <br />
                                <br />
                                LookML ingestion <b>cannot</b> currently be performed via UI-based ingestion. This is a
                                known problem the DataCatalog team is working to solve!
                                <br />
                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                                    <Button type="ghost" size="small" onClick={() => setShowLookerBanner(false)}>
                                        I have set up LookML ingestion!
                                    </Button>
                                </Space>
                            </>
                        }
                        afterClose={() => setShowLookerBanner(false)}
                    />
                )}
                <Typography.Text>
                    {showLookerBanner && <br />}
                    For more information about how to configure a recipe, see the{' '}
                    <a href={sourceDocumentationUrl} target="_blank" rel="noopener noreferrer">
                        {sourceDisplayName} source docs.
                    </a>
                </Typography.Text>
            </Section>
            <BorderedSection>
                <YamlEditor initialText={displayRecipe} onChange={setStagedRecipeYml} />
            </BorderedSection>
            <ControlsContainer>
                <Button disabled={isEditing} onClick={prev}>
                    Previous
                </Button>
                <Button disabled={!stepComplete || !applianceId} onClick={onClickNext}>
                    Next
                </Button>
            </ControlsContainer>
        </>
    );
};
