const mappings = {
    '%': '-PERCENT-',
    '#': '-HASH-',
};

const reverseMappings = {
    '-PERCENT-': '%',
    '-HASH-': '#',
};

const utilService = (() => {
    function debounce(func, delay) {
        let timeoutId;

        return function (...args) {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    // Encode special characters in a string to avoid conflicts with URL encoding
    function encodeSpecialChars(str) {
        return str.replaceAll(/[%#]/g, (match) => mappings[match]);
    }

    // Decode special characters in a string
    function decodeSpecialChars(str) {
        return str.replaceAll(/-PERCENT-|-HASH-/g, (match) => reverseMappings[match]);
    }

    return {
        debounce,
        encodeSpecialChars,
        decodeSpecialChars,
    };
})();

export default utilService;
