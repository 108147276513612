import * as XLSX from 'xlsx';

import { EntityType, PlatformPrivileges } from '../../../../types.generated';
import { GenericEntityProperties } from '../types';
import { DATA_SHEET_TABS } from '../constants';

export function isDeleteDisabled(
    entityType: EntityType,
    entityData: GenericEntityProperties | null,
    platformPrivileges: PlatformPrivileges | null | undefined,
) {
    if (entityType === EntityType.GlossaryTerm || entityType === EntityType.GlossaryNode) {
        const entityHasChildren = !!entityData?.children?.total;
        const canManageGlossaryEntity = !!entityData?.privileges?.canManageEntity;
        const canDeleteGlossaryEntity = !entityHasChildren && canManageGlossaryEntity;
        return !canDeleteGlossaryEntity;
    }
    if (entityType === EntityType.DataProduct) {
        return false; // TODO: update with permissions
    }
    if (entityType === EntityType.Domain) {
        const entityHasChildren = !!entityData?.children?.total;
        const canManageDomains = !!platformPrivileges?.manageDomains;
        const canDeleteDomainEntity = !entityHasChildren && canManageDomains;
        return !canDeleteDomainEntity;
    }
    return false;
}

export function isMoveDisabled(
    entityType: EntityType,
    entityData: GenericEntityProperties | null,
    platformPrivileges: PlatformPrivileges | null | undefined,
) {
    if (entityType === EntityType.GlossaryTerm || entityType === EntityType.GlossaryNode) {
        const canManageGlossaryEntity = !!entityData?.privileges?.canManageEntity;
        return !canManageGlossaryEntity;
    }
    if (entityType === EntityType.Domain) {
        const canManageDomains = !!platformPrivileges?.manageDomains;
        return !canManageDomains;
    }
    return false;
}

export function shouldDisplayChildDeletionWarning(
    entityType: EntityType,
    entityData: GenericEntityProperties | null,
    platformPrivileges: PlatformPrivileges | null | undefined,
) {
    if (entityType === EntityType.GlossaryTerm || entityType === EntityType.GlossaryNode) {
        const entityHasChildren = !!entityData?.children?.total;
        const canManageGlossaryEntity = !!entityData?.privileges?.canManageEntity;
        const hasTooltip = entityHasChildren && canManageGlossaryEntity;
        return hasTooltip;
    }
    if (entityType === EntityType.Domain) {
        const entityHasChildren = !!entityData?.children?.total;
        const canManageDomains = !!platformPrivileges?.manageDomains;
        const hasTooltip = entityHasChildren && canManageDomains;
        return hasTooltip;
    }
    return false;
}

// Generate a sample file for download
export const generateSampleFile = (name, blob) => {
    // Create a download link
    const link = document.createElement('a');

    // Attach the downloadable link
    link.href = URL.createObjectURL(blob);
    link.download = name;

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link from the document
    document.body.removeChild(link);
};

const validateHeaders = (expectedHeaders: string[], actualHeaders: string[]): boolean => {
    return expectedHeaders.every((header) => actualHeaders.includes(header));
};

const findMissingSheets = (expected, actual) => {
    // Convert the actual sheets to a Set for faster lookup
    const actualSet = new Set(actual);

    // Filter the expected sheets to find those not present in the actual sheets
    const missing = expected.filter((sheet) => !actualSet.has(sheet));

    return missing;
};

export const validateHeadersXlsx = (workbook, expectedSheet): boolean => {
    const sheetNames = workbook.SheetNames;
    const sheetTrack: string[] = [];

    const validateResult = sheetNames.map((sheetName) => {
        // Find sheet that matches the name or is an extended name
        const matchedSheets = expectedSheet.filter((sample) => sheetName.startsWith(sample.sheetName));

        if (!matchedSheets.length) {
            return {
                header: `Invalid Sheet: ${sheetName}`,
                message: `Remove '${sheetName}' Sheet from the file.`,
                isValid: false,
            };
        }

        // Find the closest match (you can adjust the logic here if needed)
        const matchedSheetName = matchedSheets.reduce((prev, curr) => {
            return curr.sheetName.length > prev.sheetName.length ? curr : prev;
        });

        // Get the actual sheet data
        const worksheet = workbook.Sheets[sheetName];
        const actualSheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as string[][];

        if (!actualSheetData.length) {
            sheetTrack.push(matchedSheetName.sheetName);

            return {
                header: `Empty Sheet: ${sheetName}`,
                message: `Sheet '${sheetName}' is empty.`,
                isValid: false,
            };
        }

        sheetTrack.push(matchedSheetName.sheetName);

        // Validate headers (first row)
        const actualHeaders = actualSheetData[0];
        const expectedHeaders = matchedSheetName.sheetData[0];

        if (!validateHeaders(expectedHeaders, actualHeaders)) {
            return {
                header: `Header Mismatch: ${sheetName}`,
                message: `Headers in sheet '${sheetName}' do not match expected headers.\nExpected: ${expectedHeaders}, Found: ${actualHeaders}`,
                isValid: false,
            };
        }

        return {
            isValid: true,
        };
    });

    const missingSheets = findMissingSheets(DATA_SHEET_TABS, sheetTrack);

    const missingSheetResult = missingSheets.map((sheet) => {
        return {
            header: `Not Found: ${sheet}`,
            message: `Sheet '${sheet}' is missing from the file.`,
            isValid: false,
        };
    });

    return validateResult.concat(missingSheetResult);
};
