import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row, Col } from 'antd';
import styled from 'styled-components/macro';
import { EntityType } from '../../../../../../types.generated';
import { useEntityRegistry } from '../../../../../useEntityRegistry';
import { PageRoutes } from '../../../../../../conf/Global';
import { ANTD_GRAY } from '../../../constants';
import { LineageSelector } from './LineageSelector';

import utilService from '../../../../../../utils/util.service';

type Props = {
    urn: string;
    type: EntityType;
    path: Array<string>;
    breadcrumbLinksEnabled: boolean;
};

export const BreadcrumbItem = styled(Breadcrumb.Item)<{ disabled?: boolean }>`
    &&& :hover {
        color: ${(props) => (props.disabled ? ANTD_GRAY[7] : props.theme.styles['primary-color'])};
        cursor: pointer;
    }
`;

/**
 * Responsible for rendering a clickable browse path view.
 */
export const ProfileNavBrowsePath = ({ urn, type, path, breadcrumbLinksEnabled }: Props): JSX.Element => {
    const entityRegistry = useEntityRegistry();

    const createPartialPath = (parts: Array<string>) => {
        const partialPath = parts.join('/');

        return utilService.encodeSpecialChars(partialPath);
    };

    const baseBrowsePath = `${PageRoutes.BROWSE}/${entityRegistry.getPathName(type)}`;

    const pathCrumbs = path.map((part, index) => (
        <BreadcrumbItem key={`${part || index}`} disabled={!breadcrumbLinksEnabled}>
            {breadcrumbLinksEnabled ? (
                <Link
                    to={
                        index === path.length - 1
                            ? '#'
                            : `${baseBrowsePath}/${createPartialPath(path.slice(0, index + 1))}`
                    }
                    data-testid={`legacy-browse-path-${part}`}
                >
                    {part}
                </Link>
            ) : (
                part
            )}
        </BreadcrumbItem>
    ));

    return (
        <Row className="mb-4 f-justify-center-xs" align="middle">
            <Col flex="auto">
                <Breadcrumb separator=">">
                    <BreadcrumbItem disabled={!breadcrumbLinksEnabled}>
                        {breadcrumbLinksEnabled ? (
                            <Link to={breadcrumbLinksEnabled ? baseBrowsePath : undefined}>
                                {entityRegistry.getCollectionName(type)}
                            </Link>
                        ) : (
                            entityRegistry.getCollectionName(type)
                        )}
                    </BreadcrumbItem>
                    {pathCrumbs}
                </Breadcrumb>
            </Col>
            <LineageSelector urn={urn} type={type} />
        </Row>
    );
};
