import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

interface Props {
    errorLogs?: any;
    onClose: () => void;
}

const ErrorLogContainer = styled(Modal)`
    .ant-modal-body {
        max-height: 336px;
        overflow-y: scroll;
    }
`;

const ErrorLogCard = styled.div`
    background-color: #fdf6f5;
    padding: 16px;

    span {
        color: #4c4e54;
        font-size: 12px;
    }

    p {
        color: #d96758;
        margin: 0px;
    }
`;

function ErrorLogModal(props: Props) {
    const { errorLogs, onClose } = props;

    const renderErrorLog = (header, message) => {
        return (
            <ErrorLogCard className="f-error-log-card mb-2" key={header}>
                <span>{header}</span>
                <p className="f-color-risk-very-high">{message}</p>
            </ErrorLogCard>
        );
    };

    return (
        <ErrorLogContainer
            open
            title="Error Log"
            width={780}
            centered
            closable={false}
            onCancel={onClose}
            footer={
                <Button onClick={onClose} type="default">
                    Close
                </Button>
            }
        >
            {errorLogs?.xlsxLogs?.map((log) => renderErrorLog(log.header, log.message))}

            {errorLogs?.missingHeaders?.map((headerMissing) =>
                renderErrorLog(
                    `Field '${headerMissing}' Missing`,
                    `Error: Field ${headerMissing} is missing from the csv file`,
                ),
            )}

            {errorLogs?.unexpectedHeaders?.map((invalidHeader) =>
                renderErrorLog(`Invalid '${invalidHeader}' Field`, `Error: ${invalidHeader} is an invalid field`),
            )}

            {!errorLogs && (
                <ErrorLogCard className="f-error-log-card mb-2">
                    <span>Invalid File Format</span>
                    <p className="f-color-risk-very-high">
                        Error: The file uploaded is invalid, It must be in csv format
                    </p>
                </ErrorLogCard>
            )}
        </ErrorLogContainer>
    );
}

export default ErrorLogModal;
