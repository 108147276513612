import * as React from 'react';
import { Menu, Typography, Divider } from 'antd';
import {
    BankFilled,
    // BankOutlined,
    // SafetyCertificateOutlined,
    UsergroupAddOutlined,
    // ToolOutlined,
    // FilterOutlined,
    TeamOutlined,
    // PushpinOutlined,
} from '@ant-design/icons';
import { Redirect, Route, useHistory, useLocation, useRouteMatch, Switch } from 'react-router';
import styled from 'styled-components';
import { ANTD_GRAY } from '../entity/shared/constants';
import { ManageIdentities } from '../identity/ManageIdentities';
import { ManagePermissions } from '../permissions/ManagePermissions';
import { useAppConfig } from '../useAppConfig';
import { AccessTokens } from './AccessTokens';
import { Preferences } from './Preferences';
import { ManageViews } from '../entity/view/ManageViews';
import { useUserContext } from '../context/useUserContext';
import { ManageOwnership } from '../entity/ownership/ManageOwnership';
import ManagePosts from './posts/ManagePosts';

const PageContainer = styled.div`
    display: flex;
    background-color: #ffffff;
`;

const SettingsBarContainer = styled.div`
    padding-top: 16px;
    min-height: 100vh;
    border-right: 1px solid ${ANTD_GRAY[5]};

    @media (max-width: 992px) {
        border-right: 0;
        min-height: auto;
        margin-bottom: 8px;
        border-bottom: 1px solid ${ANTD_GRAY[5]};
    }
`;

const SettingsBarHeader = styled.div`
    && {
        padding: 0 24px;
    }
    margin-bottom: 16px;
`;

const ThinDivider = styled(Divider)`
    margin: 0px;
`;

const ItemTitle = styled.span`
    margin-left: 8px;
`;

/**
 * URL Paths for each settings page.
 */
const PATHS = [
    { path: 'tokens', content: <AccessTokens /> },
    { path: 'identities', content: <ManageIdentities /> },
    { path: 'permissions', content: <ManagePermissions /> },
    { path: 'preferences', content: <Preferences /> },
    { path: 'views', content: <ManageViews /> },
    { path: 'ownership', content: <ManageOwnership /> },
    { path: 'posts', content: <ManagePosts /> },
];

/**
 * The default selected path
 */
const DEFAULT_PATH = PATHS[0];

export const SettingsPage = () => {
    const { path, url } = useRouteMatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const subRoutes = PATHS.map((p) => p.path.replace('/', ''));
    const currPathName = pathname.replace(path, '');
    const trimmedPathName = currPathName.endsWith('/') ? pathname.slice(0, pathname.length - 1) : currPathName;
    const splitPathName = trimmedPathName.split('/');
    const providedPath = splitPathName[1];
    const activePath = subRoutes.includes(providedPath) ? providedPath : DEFAULT_PATH.path.replace('/', '');

    const me = useUserContext();
    const { config } = useAppConfig();

    const isPoliciesEnabled = config?.policiesConfig.enabled;
    const isIdentityManagementEnabled = config?.identityManagementConfig.enabled;
    // const isViewsEnabled = config?.viewsConfig.enabled;
    // const { readOnlyModeEnabled } = config.featureFlags;

    const showPolicies = (isPoliciesEnabled && me && me?.platformPrivileges?.managePolicies) || false;
    const showUsersGroups = (isIdentityManagementEnabled && me && me?.platformPrivileges?.manageIdentities) || false;
    const showOwnershipTypes = me && me?.platformPrivileges?.manageOwnershipTypes;
    // const showViews = isViewsEnabled || false;
    // const showHomePagePosts = me && me?.platformPrivileges?.manageGlobalAnnouncements && !readOnlyModeEnabled;

    return (
        <PageContainer className="settings-page f-flex-column-xs">
            <SettingsBarContainer>
                <SettingsBarHeader>
                    <Typography.Title className="page-heading">Settings</Typography.Title>
                    <Typography.Paragraph type="secondary" className="mt-2">
                        Manage your Data Catalog settings.
                    </Typography.Paragraph>
                </SettingsBarHeader>
                <ThinDivider />
                <Menu
                    mode="inline"
                    selectable={false}
                    role="list"
                    className="f-width-100-xs"
                    selectedKeys={[activePath]}
                    style={{ width: 272, marginTop: 8 }}
                    onClick={(newPath) => {
                        history.replace(`${url}/${newPath.key}`);
                    }}
                >
                    {/* TODO: commented the Access Tokens tab for now. */}

                    {/* <Menu.ItemGroup title="Developer">
                        <Menu.Item key="tokens">
                            <SafetyCertificateOutlined />
                            <ItemTitle>Access Tokens</ItemTitle>
                        </Menu.Item>
                    </Menu.ItemGroup> */}
                    {(showPolicies || showUsersGroups) && (
                        <Menu.ItemGroup title="Access">
                            {showUsersGroups && (
                                <Menu.Item key="identities" aria-label="Users & Groups" tabIndex={0} role="listitem">
                                    <UsergroupAddOutlined />
                                    <ItemTitle>Users</ItemTitle>
                                </Menu.Item>
                            )}
                            {showPolicies && (
                                <Menu.Item key="permissions" aria-label="Permissions" tabIndex={0} role="listitem">
                                    <BankFilled />
                                    <ItemTitle>Permissions</ItemTitle>
                                </Menu.Item>
                            )}
                        </Menu.ItemGroup>
                    )}

                    <Menu.ItemGroup title="Manage">
                        {/* {showViews && (
                            <Menu.Item key="views">
                                <FilterOutlined /> <ItemTitle>My Views</ItemTitle>
                            </Menu.Item>
                        )} */}
                        {showOwnershipTypes && (
                            <Menu.Item key="ownership" aria-label="Ownership Types" tabIndex={0} role="listitem">
                                <TeamOutlined /> <ItemTitle>Ownership Types</ItemTitle>
                            </Menu.Item>
                        )}
                        {/* {showHomePagePosts && (
                            <Menu.Item key="posts">
                                <PushpinOutlined /> <ItemTitle>Home Page Posts</ItemTitle>
                            </Menu.Item>
                        )} */}
                    </Menu.ItemGroup>

                    {/* <Menu.ItemGroup title="Preferences">
                        <Menu.Item key="preferences">
                            <ToolOutlined />
                            <ItemTitle>Appearance</ItemTitle>
                        </Menu.Item>
                    </Menu.ItemGroup> */}
                </Menu>
            </SettingsBarContainer>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${pathname}${pathname.endsWith('/') ? '' : '/'}${DEFAULT_PATH.path}`} />
                </Route>
                {PATHS.map((p) => (
                    <Route path={`${path}/${p.path.replace('/', '')}`} render={() => p.content} key={p.path} />
                ))}
            </Switch>
        </PageContainer>
    );
};
